/* eslint-disable camelcase */

import { Application } from '@app-tray-ui/react-wrapper';
import { PagesList } from './layout';
import { LocalizationConfigFromResponse } from '../../services/localization/types';

enum NoProgramsStrategy {
  pageNotFound,
  mockedApp,
  teaser
}

export interface Config {
  defaultDateRange: string;
  advancedAnalyticsDateRange: string;
  defaultPredictiveDateRange?: string;
  defaultDistanceMetrics?: DistanceMetrics;
  defaultLocale?: string;
  defaultMapCoords?: { lat: number; lng: number };
  mockedMerchantSequenceKey: string;
  defaultTemperatureMetrics?: TemperatureMetrics;
  countryCode?: string;
  isEnterprise?: boolean;
  name: string;
  isActionButtonsDisabled?: boolean;
  isAppHubPartner: boolean;
  localizationSettings?: LocalizationConfigFromResponse;
  acePublicSitesDomain?: string;
  oneTrustID: string;
}

export type DistanceMetrics = 'kilometers' | 'miles';

export type ReturningFrequency = '6' | '12' | '24';

export type TemperatureMetrics = 'fahrenheit' | 'celsius';

export interface Settings {
  language?: string;
  returningFrequency?: ReturningFrequency;
  distanceMetrics: DistanceMetrics;
  temperatureMetrics: TemperatureMetrics;
}

export interface UserLocation {
  merchant_name: string;
  merchant_number: string;
  merchant_sequence_key: string;
  city: string;
  zip: string;
  state: string;
  address_1: string;
  address_2: string;
  timezone: string;
  email: string;
  phone: string;
  chain_oid?: string;
}

export enum LocationEvent {
  selectItem = 'selectItem',
  unSelectItem = 'unSelectItem',
  selectAll = 'selectAll',
  unSelectAll = 'unSelectAll',
  expand = 'expand'
}

export enum EventHandler {
  locationSelector = 'locationSelector',
  testGroup = 'locationSelectorTestGroup',
  controlGroup = 'locationSelectorControlGroup'
}

export type LocationsList = UserLocation[];

export interface AutoAlertsStatus {
  newCustomersEnabled: boolean;
  returnedCustomersEnabled: boolean;
  newCustomersThreshold: number;
  returnedCustomersThreshold: number;
}

export interface AutoAlertsStatusConfig {
  [key: string]: AutoAlertsStatus;
}

export interface DigestStatus {
  updatedAt: string;
  email: string;
  enabled: boolean;
}

export interface DigestStatusConfig {
  [key: string]: DigestStatus;
}

export interface SettingsConfig {
  [key: string]: Settings;
}

export interface MerchantPages {
  [key: string]: string[];
}

export interface MerchantPrograms {
  [key: string]: string[];
}

export interface Programs {
  alias: string;
  programId: string;
}

export interface ServiceVersion {
  name: string;
  version: string;
}

export interface AppHubTrayItem {
  id: string;
  favorite: boolean;
  icon: string;
  link: string;
  name: string;
  order: number;
  target: string;
  supportedDevices: string[];
  status: unknown;
  activatedAt: string;
  updatedAt: string;
}

interface LoyaltyPointsSetup {
  threshold: number;
  values: {
    under25: number;
    under50: number;
    above50: number;
  };
}

export interface SettingsState {
  locations: LocationsList;
  autoAlertsStatus: AutoAlertsStatusConfig;
  digestStatus: DigestStatusConfig;
  loading: boolean;
  config: Config;
  settings: SettingsConfig;
  pendingUpdate?: boolean;
  updatedAt?: number;
  merchantPages: MerchantPages;
  merchantPrograms: MerchantPrograms;
  versions: ServiceVersion[];
  programs: Programs[];
  isDemo: boolean;
  partnerId: string;
  cesPlansDemoEnv: boolean;
  isCustomDemoData: boolean;
  userId?: string;
  gpUserId?: string;
  jwt?: string;
  appHubTrayItems: {
    loading: boolean;
    data: Application[];
    error?: string;
  };
  loyaltyPointsSetup?: LoyaltyPointsSetup[];
  accountName: string;
  katanaKey?: string;
  partnerCountryCode: string;
}

export interface SetDigestRequest {
  digestEnabled: boolean;
  digestEmail: string;
  mskList: string[];
}

export interface UpdateSettingsRequest {
  msk: string[];
  value: Settings;
}

export interface MyAccountSuccessResponse {
  digestResponse?: DigestStatusConfig;
  settingsResponse?: SettingsConfig;
}

export interface AutoAlertRequest {
  mskList: string[];
  newCustomersEnabled: boolean;
  newCustomersThreshold: number | string;
  returnedCustomersEnabled: boolean;
  returnedCustomersThreshold: number | string;
}

export interface NotificationsSettingsRequest {
  digest: {
    locations: string[];
    enabled: boolean;
  };
  alert: AutoAlertRequest;
}

export interface NotificationSettingsSuccess {
  digest: DigestStatusConfig;
  alerts: AutoAlertsStatusConfig;
}

interface ConfigFromResponse extends Config {
  noPagesStrategy: keyof typeof NoProgramsStrategy;
}

export interface Program {
  alias: string;
  masterCardDependent: boolean;
  merchantCentricDependent: boolean;
  programId: string;
}

export interface SettingsDataResponse {
  autoAlertsStatus: AutoAlertsStatusConfig;
  config: ConfigFromResponse;
  digestStatus: DigestStatusConfig;
  emSettings: any;
  locations: LocationsList;
  merchantPages: MerchantPages;
  merchantPrograms: {
    [key: string]: string[];
  };
  programs: Program[];
  pages: PagesList;
  settings: SettingsConfig;
  isMocked: boolean;
  versions: ServiceVersion[];
  selectedLocations: string[];
  demoEnv: boolean;
  partnerId: string;
  cesPlansDemoEnv: boolean;
  isCustomDemoData: boolean;
  visitorToken: string;
  userId?: string;
  gpUserId?: string;
  jwt?: string;
  variables: Record<
    'pendoKey' | 'googleMapsKey' | 'weatherKey' | 'weatherHost' | 'logTranslations' | 'crmS3ImageUrl',
    any
  >;
  loyaltyPointsSetup: LoyaltyPointsSetup[];
  accountName: string;
  katanaKey?: string;
  partnerCountryCode: string;
}
