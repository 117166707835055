import React from 'react';
import { get } from 'lodash';
import { useSelector } from 'react-redux';
import { localizationService } from 'services/localization';
import { MailBox } from 'store/emailMarketing/emailTemplateEditor';
import { getKatanaKey } from 'store/settings';
import { MailBox as MailBoxComponent } from './MailBoxes';
import { getStateNameByCode } from './utils';
import { EmailMarketingSettings } from '../../store/emailMarketing/settings';

interface PropTypes {
  styles: any;
  components?: MailBox[];
  settings: EmailMarketingSettings & { customerLocale: string };
  translations: { [key: string]: string };
  isDanubeWithLoyalty?: boolean;
  isDanubeAccount?: boolean;
  accountName?: string;
  katanaKey?: string;
}

export const Body = (props: PropTypes) => {
  const { styles, settings, components = [], translations = {} } = props;

  const { customer } = settings;

  return (
    <>
      {components.map((box: MailBox) => (
        <table
          key={box.id}
          className="full"
          cellPadding="0"
          cellSpacing="0"
          style={{
            border: '0 !important',
            textAlign: 'center',
            margin: 'auto',
            ...styles
          }}
        >
          <tbody>
            <tr>
              <td>
                <MailBoxComponent translations={translations} styles={styles} box={box} settings={customer} />
              </td>
            </tr>
          </tbody>
        </table>
      ))}
    </>
  );
};

export const Footer = (props: PropTypes) => {
  const { settings, styles = {}, translations, isDanubeWithLoyalty, isDanubeAccount, accountName, katanaKey } = props;

  /* eslint-disable */
  const defaultCustomerSettings = {
    crmAppUrl: '',
    state: -1,
    organizationName: '',
    address_1: '',
    address_2: '',
    city: '',
    zip: '',
    publicSitesDomain: '',
    chockStoneDomain: '',
    locations: []
  };
  /* eslint-enable */
  const { customer = defaultCustomerSettings } = settings;
  const regions = get(settings, 'locationData.regions', []);
  const language = localizationService.getLanguage();
  const { publicSitesDomain, chockStoneDomain, locations } = customer;

  const chain = locations && locations[0] ? locations[0].businessId : '';
  const katanaApiKeyStr = katanaKey ? `&ak=${katanaKey}` : '';
  // eslint-disable-next-line max-len
  const euUnsubscribeParams = `account=${accountName}&language=${language}&chain=${chain}&domain=${chockStoneDomain}${katanaApiKeyStr}&user=%%Email%%&sva=%%ThirdPartyLoyaltyCard%%`;
  const crmUnsubscribeParams = `account=${accountName}&locale=${settings.customerLocale}&trackingCode=%%TrackingCode%%`;

  const crmUnsubscribeUrl =
    publicSitesDomain &&
    `${publicSitesDomain}/unsubscribe/email-marketing?${isDanubeAccount ? euUnsubscribeParams : crmUnsubscribeParams}`;

  const danubeUnsubscribeLoyaltyProgramUrl = publicSitesDomain
    ? `${publicSitesDomain}/opt-out/loyalty?${euUnsubscribeParams}`
    : '';

  const stateName = getStateNameByCode(regions, customer.state);

  const tableStyle = {
    border: '0 !important',
    textAlign: 'center',
    margin: 'auto',
    ...styles
  };

  return (
    <table id="footer" className="full" cellPadding="0" cellSpacing="0" style={tableStyle}>
      <tbody>
        <tr>
          <td>
            <br />
            {/* eslint-disable-next-line */}
            <div>
              {customer.organizationName} | {customer.address_1} |{' '}
              {customer.address_2 !== '' && `${customer.address_2} | `}
              {customer.city} | {stateName !== '' && `${stateName} | `}
              {customer.zip}
            </div>
            <br />

            <div id="bsd-unsubscribe-footer">
              {isDanubeWithLoyalty ? (
                <>
                  <a style={tableStyle} href={danubeUnsubscribeLoyaltyProgramUrl}>
                    {translations.mailTableOptOutFromTheLoyaltyProgram}
                  </a>
                  <br />
                  <br />
                  <br />
                  <a style={tableStyle} href={crmUnsubscribeUrl}>
                    {translations.mailTableUnsubscribeFromEmailMarketing}
                  </a>
                </>
              ) : (
                <a style={tableStyle} href={crmUnsubscribeUrl}>
                  {translations.mailTableUnsubscribeText}
                </a>
              )}
            </div>
            <br />
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export const EmailTable = (props: PropTypes) => {
  const {
    styles = {},
    components = [],
    settings,
    translations,
    isDanubeWithLoyalty,
    isDanubeAccount,
    accountName,
    katanaKey
  } = props;

  return (
    <table
      id="outer-table"
      className="full"
      cellPadding="2"
      cellSpacing="0"
      style={{
        border: '0 !important',
        textAlign: 'center',
        width: '100%'
      }}
    >
      <tbody>
        <tr>
          <td>
            <Body translations={translations} styles={styles} components={components} settings={settings} />
            <Footer
              translations={translations}
              styles={styles}
              settings={settings}
              isDanubeWithLoyalty={isDanubeWithLoyalty}
              isDanubeAccount={isDanubeAccount}
              accountName={accountName}
              katanaKey={katanaKey}
            />
          </td>
        </tr>
      </tbody>
    </table>
  );
};
